import '../sass/pages/app.scss';
import 'jquery';
import validate from 'jquery-validation';
import datepicker from 'air-datepicker';
import axios from 'axios';

const app = (() => {

    const   formDonation = $("#frmDonation"),
            buttonForm = $('.form-donation').find('.form-control--button');

    const init = () => {
        fnDatePickerInput();
        fnValidateForm();
    }

    const fnDatePickerInput = () =>{
        $.fn.datepicker.language['es'] = {
            days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            daysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            daysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            months: ['Enero','Febrero','Marzo','Abril','Mayo','Junio', 'Julio','Augosto','Septiembre','Octubre','Noviembre','Diciembre'],
            monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            today: 'Hoy',
            clear: 'Limpiar',
            dateFormat: 'yyyy/mm/dd',
            timeFormat: 'hh:ii aa',
            firstDay: 1
        }
        
        $('#txtDate').datepicker({
            language: 'es',
            view: 'years',
            maxDate: new Date(),
            autoClose: true
        });
    }

    const fnValidateForm = () =>{

        /** Custom rules validate */
        $.validator.addMethod('alphanumericspace', function(value, element) {
            return this.optional(element) || /([0-9a-zA-Z\s])$/.test(value);
        }, 'No se aceptan caracteres especiales'); 
        /** [END] Custom rules validate */
        formDonation.validate({
            rules:{
                'txtDocument':{
                    digits: true,
                    required	: true,
                },
                'txtName':{
                    alphanumericspace : true,
                    required	: true,
                },
                'txtEmail':{
                    required	: true,
                    email 		: true
                },
                'txtCountry':{
                    required	: true,
                },
                'txtCity':{
                    required	: true,
                },
                'txtAddres':{
					required	: true
                },
                'chkLegales':{
					required	: true
                }
            },
            messages:{
                'txtDocument':{
                    digits      : 'Ingrese únicamente números',
                    required	: 'El número de identificación o NIT es requerido',
                },
                'txtName':{
                    alphanumericspace : 'No se aceptan caracteres especiales (puntos, comas, tildes, entre otros)',
                    required	: 'Tus nombres o razón social es requerida',
                },
                'txtEmail':{
                    required	: 'Tu email es requerido',
                    email 		: 'El formato debe ser ejemplo@ejemplo.com'
                },
                'txtCountry':{
                    required	: 'El país de residencia es requerido',
                },
                'txtCity':{
                    required	: 'La ciudad de residencia es requerida',
                },
                'txtAddres':{
					required	: 'La dirección de residencia es requerida',
                },
                'chkLegales':{
					required	: 'Se deben aceptar términos y condiciones'
                }
            },
            errorElement: "span",
            errorPlacement: function ( error, element ) {
				if(element.attr("name") === 'chkLegales'){
                    error.insertAfter(element.next());	
				}else{
					error.insertAfter(element);
				}
			},
            submitHandler: function(form) {

                buttonForm.prop('disabled', true);
                buttonForm.addClass('loading');

                fnVerifySarlaftData(form);
			}
        });
    }

    const fnVerifySarlaftData = (form) => {

        const   dataForm = {},
                containerMessages = $('.form-group--messages');

        containerMessages.empty().hide();
        containerMessages.removeClass('success').removeClass('error');

        dataForm.document = $(form).find('#txtDocument').val();	
        dataForm.name = $(form).find('#txtName').val();
        dataForm.email = $(form).find('#txtEmail').val();
        dataForm.date = $(form).find('#txtEmail').val();
        dataForm.phone = $(form).find('#txtEmail').val();
        dataForm.country = $(form).find('#txtCountry').val();
        dataForm.city = $(form).find('#txtCity').val();
        dataForm.address = $(form).find('#txtAddres').val();
        dataForm.legales = $(form).find('#chkLegales').val() == 'true' ? true : false;
        dataForm.origin = $(form).find('#origin').val();

        console.log('dataForm', dataForm);

        try{
            let response = axios.post(
                'https://apisarlaft.paymentsbakery.com.co/api', 
                dataForm);

                response.then((response) => {
                    const statusCode = response.status;
                    if(statusCode === 200){
                        containerMessages.text('Tu donación puede ser recibida').addClass('success').show();
                    }else if(statusCode === 201){
                        containerMessages.text('Lo sentimos, tu donación no puede ser recibida. Pronto te contactaremos').addClass('error').show();
                    }else{
                        containerMessages.text('Lo sentimos, se ha presentado un error').addClass('error').show();
                    }

                    setTimeout(() => {
                        containerMessages.hide();
                    }, 6000);

                    buttonForm.prop('disabled', false);
                    buttonForm.removeClass('loading');
                    form.reset();

                }).catch(error => {
                    let errorCode = error.response.data.message[0].code,
                        errorMessage = error.response.data.message[0].code,
                        errorSarlaft = error.response.data.message[0].errors.original;

                    console.error('::: ERROR ENDPOINT: ', errorCode, errorMessage, errorSarlaft);
                    containerMessages.text('Lo sentimos, se ha presentado un error interno. Intente realizar su donación más tarde.').addClass('error').show();
                    buttonForm.prop('disabled', false);
                    buttonForm.removeClass('loading');
                    form.reset();

                });
        }catch(error){
            console.error('Lo sentimos, se ha presentado un error', error);
        }

        return;

    }

    return { init: init };

})();

$(document).ready(function () {
    app.init();
});